$enable-ltr: true; /* stylelint-disable-line scss/dollar-variable-default */
$enable-rtl: true; /* stylelint-disable-line scss/dollar-variable-default */

// Import styles
@import '@coreui/coreui-pro/scss/coreui';
@import '@coreui/coreui-pro/scss/themes/dark';

@import 'layout';

// If you want to add custom CSS you can put it here.
@import 'custom';

.accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.125);
}

.truncated-text {
  white-space: nowrap;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}